@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
	background: #ffffff;
	background-size: 100%;
	min-height: 100%;
	overflow-x: hidden;
	font-weight: 300;
	font-family: "Nunito";
}

.h2 {
	font-size: 2.4em;
	line-height: 1.1;
	font-weight: bold;
	font-family: "Anton";
}

@media (min-width: 768px) {
	.h2 {
		font-size: 4em;
	}
}

.h3 {
	font-size: 1.4em;
	font-weight: bold;
	font-family: "Anton";
}

.bg-rosa {
	background-color: #fae8f4;
}

.bg-pink-50 {
	background-color: #fa3099;
}
.btn {
	background: #df7e2d;
	text-align: center;
	padding-left: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	padding-right: 20px;
	font-family: "Anton";
}
.btn-gray {
	background: #898989;
	text-align: center;
	padding-left: 20px;
	padding-bottom: 10px;
	padding-top: 10px;
	padding-right: 20px;
	border-radius: 20px;
}

.stroke-pink {
	stroke: #fa3099;
}

.bg-pink {
	background-color: #fa3099;
}

.text-pink {
	color: FA3099;
}

.sogehts-wrap {
	margin: 0 auto;
	max-width: 1000px;
}

@media (max-width: 999px) {
	.sogehts-wrap {
		max-width: calc(100% - 4rem);
	}
}

.sogehts {
	display: grid;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	font-size: 2em;
	font-weight: bold;
	line-height: 1.1;
	font-size: 2em;
	text-align: center;
}

@media (min-width: 768px) {
	.sogehts {
		text-align: left;
		font-size: 2.5em;
		flex-direction: row;
	}
}

.sogehts p:first-child {
	width: 1.5em;
	height: 1.5em;
	border-radius: 1em;
	display: grid;
	flex-shrink: 0;
	justify-self: center;
	align-self: center;
	margin-bottom: 1rem;
	text-align: center;
	padding-top: 12px;
	margin-bottom: 20px;
	font-size: 52px;
}

@media (max-width: 768px) {
	.sogehts p:first-child {
		width: 1.8em;
		height: 1.8em;
		font-size: 32px;
		padding-top: 12px;
	}
}
@media (min-width: 768px) {
	.sogehts p:first-child {
		margin-bottom: 30px;
		margin-right: 1.5rem;
	}
}

.sogehts .badge {
	margin-bottom: 0;
	margin-right: 1rem;
	width: 255px;
}

.upload.illu > img {
	width: 120px;
}

.upload section {
	max-width: 500px;
}

/* simple captcha overwrite */
#reload_href {
	text-decoration: underline;
	line-height: 2rem;
	color: #9e6a0a !important;
}

#canv {
	float: left;
	margin-right: 1rem;
	border-radius: 0.4rem;
}

/* left align date input field */
input::-webkit-date-and-time-value {
	/* ios 16+ fix */
	text-align: left;
}

/* Remove number arrows from input field */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type="number"] {
	-moz-appearance: textfield;
}

.teaser-advice .grid {
	display: block !important;
}

@media (max-width: 1023px) {
	input[type="date"] {
		display: block;
		min-width: 95%;
		-webkit-appearance: textfield;
		-moz-appearance: textfield;
		display: flex;
	}
}
.header-item {
	font-size: 32px;
}
.header-item-mobile {
	font-size: 24px;
	color: #ffffff;
}
