/* Slider */
.slick-slider *:focus-visible {
	outline: none;
}
.slick-slider {
	position: relative;
	display: block;
	box-sizing: border-box;
	-webkit-touch-callout: none;
	-webkit-user-select: none;
	-khtml-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
	-ms-touch-action: pan-y;
	touch-action: pan-y;
	-webkit-tap-highlight-color: transparent;
}
.slick-list {
	position: relative;
	display: block;
	margin: 0;
	padding: 0;
}
.slick-list:focus {
	outline: none;
}
.slick-list.dragging {
	cursor: pointer;
	cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);
}
.slick-track {
	position: relative;
	left: 0;
	top: 0;
	display: block;
	margin-left: auto;
	margin-right: auto;
}
.slick-track:before,
.slick-track:after {
	content: "";
	display: table;
}
.slick-track:after {
	clear: both;
}
.slick-loading .slick-track {
	visibility: hidden;
}
.slick-slide {
	float: left;
	height: 100%;
	min-height: 1px;
	display: none;
}
[dir="rtl"] .slick-slide {
	float: right;
}
.slick-slide img {
	display: block;
}
.slick-slide.slick-loading img {
	display: none;
}
.slick-slide.dragging img {
	pointer-events: none;
}
.slick-initialized .slick-slide {
	display: block;
}
.slick-loading .slick-slide {
	visibility: hidden;
}
.slick-vertical .slick-slide {
	display: block;
	height: auto;
	border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
	display: none;
}
.slick-dots {
	text-align: center;
	padding-left: 0;
	line-height: 10px;
	margin-top: 20px;
	margin-bottom: 16px;
}
.slick-dots button {
	content: "";
	color: transparent;
}
.slick-dots li {
	display: inline-block;
	opacity: 0.2;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	margin: 0 4px;
	opacity: 1;
	background-color: #ffffff;
}
.slick-dots li.slick-active {
	background-color: #df7e2d;
}
.slick-arrow {
	position: absolute;
	top: calc(50% - 32px);
	transform: translateX(-50%, -50%);
	z-index: 2;
	font-size: 0;
	height: 48px;
	width: 48px;
	background-size: auto 50%;
	border: none;
	outline: none;
	background-color: transparent;
	background-repeat: no-repeat;
	background-position: center;
	cursor: pointer;
}
.slick-arrow.slick-prev {
	left: 2rem;
	background: url("../public/images/volvic/slick-arrow-prev.svg") no-repeat;
}
.slick-arrow.slick-next {
	right: 2rem;
	background: url("../public/images/volvic/slick-arrow-next.svg") no-repeat;
}
